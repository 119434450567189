import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LIMITS } from "../../../config";
import { fetchData } from "../../../config/service";
import { Checkbox, Select } from "antd";
import toast from "react-hot-toast";
import { StatusModal, ViewNotesModal } from "../modals";
import { Loader } from "../../../components";
import dayjs from "dayjs";

const cardData = [
  {
    id: 1,
    avatar: "/media/img.jpg",
    name: "Patric Watson",
    timeAgo: "313.3 hours ago",
    callNotes: "Call Notes",
    revenue: "1 to 3cr",
    age: 40,
    edition: "-",
    city: "Panjim",
    referredBy: "-",
    story:
      "As a founder/creator, I am looking to engage with people from various & diverse backgrounds to learn about new ideas and pivots that I can take in my business.",
    reasonsToAttend:
      "Personal & Professional Creative Growth, I want to learn from a diverse set of creators.",
    growthAreas:
      "Creative Block / Find inspiration, Refining my Business ideas & plans",
    status: "Status Under Review",
    appNumber: "App# 17085 city Panjim",
    badges: ["Events", "A Self-run...", "BHX"],
  },
  {
    id: 2,
    avatar: "/media/img.jpg",
    name: "John Doe",
    timeAgo: "150 hours ago",
    callNotes: "Call Details",
    revenue: "5 to 7cr",
    age: 35,
    edition: "Premium",
    city: "Mumbai",
    referredBy: "Jane Smith",
    story:
      "Looking to scale my startup and connect with like-minded entrepreneurs for collaborative growth.",
    reasonsToAttend:
      "Networking with industry leaders, learning from experienced entrepreneurs.",
    growthAreas: "Scaling operations, refining business strategy.",
    status: "Application Approved",
    appNumber: "App# 17090 city Mumbai",
    badges: ["Networking", "Entrepreneurship", "Growth"],
  },
  {
    id: 3,
    avatar: "/media/img.jpg",
    name: "Alice Johnson",
    timeAgo: "80 hours ago",
    callNotes: "Meeting Summary",
    revenue: "3 to 5cr",
    age: 28,
    edition: "Standard",
    city: "Delhi",
    referredBy: "-",
    story:
      "Interested in exploring new markets and opportunities for expansion.",
    reasonsToAttend: "Market expansion, learning new business models.",
    growthAreas: "Market research, business expansion strategies.",
    status: "Application Under Review",
    appNumber: "App# 17095 city Delhi",
    badges: ["Expansion", "Research", "Strategy"],
  },
];

export default function CurationCentral() {
  const { id } = useParams();

  const [sending, setSending] = useState(false);

  const [loading, setLoading] = useState(true);
  const [bookingDataLoading, setBookingDataLoading] = useState(false);
  const [starLoading, setStarLoading] = useState(false);
  const [curationNoteLoading, setCurationNoteLoading] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [userData, setUserData] = useState([]);

  const [openCardIds, setOpenCardIds] = useState({});
  const [selectedScore, setSelectedScore] = useState(null);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [viewNotesModaL, setViewNotesModal] = useState(false);

  const [statusList, setStatusList] = useState([]);
  const [isStarProfile, setIsStarProfile] = useState(null);

  const [curationNoteOptions, setCurationNoteOptions] = useState(null);
  const [curationNote, setCurationNote] = useState(null);

  const [editionsData, setEditionsData] = useState({
    editionsOptions: [],
    data: [],
  });
  const [editionsStatusOptions, setEditionsStatusOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [editionsOptions, setEditionsOptions] = useState([]);

  const [filterData, setFilterData] = useState({
    project: null,
    status: null,
    edition: null,
    priority: false,
  });
  const [bookingData, setBookingData] = useState([]);
  const [statusModalData, setStatusModalData] = useState({
    uuid: "",
    status: "",
  });
  const [viewNotesModalData, setViewNotesModalData] = useState({
    userId: "",
    is_curation_note: false,
  });

  const handleToggle = (id) => {
    setOpenCardIds((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    getEditionsStatus();
    getEditions();
    getProjectOptions();
  }, []);

  // useEffect(() => {
  //   if (id) {
  //     getData();
  //     getStatus();
  //     getCurationNoteOptions();
  //     // getEditionsLitFromBookingData();
  //   }
  // }, [id]);

  useEffect(() => {
    getStatus();
    getCurationNoteOptions();
  }, []);

  useEffect(() => {
    getBookingData();
  }, [limit, activePage, filterData, id]);

  const getEditionsStatus = () => {
    fetchData(`api/accounts/user-choices/?type=user_status_choices`).then(
      (res) => {
        if (res.success) {
          const data = res["user_status_choices"]?.map((e) => {
            return {
              label: e?.display_name,
              value: e?.value,
            };
          });
          setEditionsStatusOptions(data);
        } else {
          setEditionsStatusOptions([]);
          toast.error(res.message);
        }
      }
    );
  };

  const getProjectOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectOptions(data);
      } else {
        setProjectOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsOptions(data);
      } else {
        setEditionsOptions([]);
        toast.error(res.message);
      }
    });
  };

  // const getEditionsLitFromBookingData = () => {
  //   fetchData(
  //     `api/booking/?limit=${limit}&offset=${
  //       (activePage - 1) * limit
  //     }&user=${id}&edition=${filterData?.edition || ""}`,
  //     "get"
  //   ).then((res) => {
  //     setLoading(false);
  //     if (res.success) {
  //       const data = res.data;
  //       const options = data.map((item) => {
  //         return { label: item.edition_name, value: item.edition };
  //       });
  //       setEditionsData({ editionsOptions: options, data: data });
  //     } else {
  //       toast.error(res.message);
  //     }
  //   });
  // };

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  console.log(id, "id");
  const getBookingData = () => {
    setBookingDataLoading(true);

    // If id has a value, send an empty string, otherwise send the actual id
    const userId = id === ":id" ? "" : id;

    fetchData(
      `api/booking/?user=${userId || ""}&limit=${limit}&offset=${
        (activePage - 1) * limit
      }&status=${filterData?.status || ""}&project=${
        filterData?.project || ""
      }&edition=${filterData?.edition || ""}`,
      "get"
    ).then((res) => {
      setBookingDataLoading(false);
      if (res.success) {
        setBookingData(res.data);

        // Construct an object that maps user UUIDs to their scores
        const scores = res.data.reduce((acc, item) => {
          if (item.user && item.user.uuid) {
            acc[item.user.uuid] = item.user.score;
          }
          return acc;
        }, {});

        // Set the scores object to the selectedScores state
        setSelectedScores(scores);

        // Set the scores object to the selectedScores state
        setSelectedScores(scores);
        setTotalRecords(res.count);
      } else {
        setBookingData([]);
        toast.error(res.message);
      }
    });
  };

  console.log(bookingData, "bookingData");

  const getData = () => {
    fetchData(`customer/api/customer/${id}/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        if (res.is_curation_note) {
          getCurationNote();
        }
        setUserData([res]);
        setSelectedScore(res?.score);
        setIsStarProfile(res?.user_profile?.is_star_profile);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getCurationNoteOptions = () => {
    fetchData(
      `api/curation_note/question-answer/?limit=50&offset=0`,
      "get"
    ).then((res) => {
      if (res.success) {
        setCurationNoteOptions(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getCurationNote = (userId) => {
    fetchData(`api/curation_note/${userId}`, "get").then((res) => {
      setCurationNoteLoading(false);
      if (res.success) {
        setCurationNote(res);
      } else {
        setCurationNote([]);
        toast.error(res.message);
      }
    });
  };

  const onSubmitUser = async (userId) => {
    setSending(true);

    // Get the selected score for this specific user
    const userSelectedScore = selectedScores[userId];

    // Prepare the request body
    const body = {
      ...(userSelectedScore !== userData?.score
        ? { score: userSelectedScore }
        : {}),
    };

    // const body = {
    //   ...(selectedScore !== userData?.score ? { score: selectedScore } : {}),
    // };

    fetchData(`customer/api/customer/${userId}/`, "PATCH", body, "", true).then(
      (res) => {
        toast.remove();
        setSending(false);
        if (res.success) {
          // setLoading(true);
          // getData();
          setBookingDataLoading(true);
          getBookingData();
          toast.success("Success");
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const handleStarClick = async (userId, star) => {
    const newStarProfile = !star;
    setIsStarProfile(newStarProfile);

    const body = {
      is_star_profile: newStarProfile,
    };

    fetchData(`customer/api/customer/${userId}/`, "PATCH", body, "", true).then(
      (res) => {
        toast.remove();
        setSending(false);
        if (res.success) {
          // setLoading(true);
          // getData();
          setBookingDataLoading(true);
          getBookingData();
          const successMessage = newStarProfile
            ? "Profile starred successfully!"
            : "Profile unstarred successfully!";
          toast.success(successMessage);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const scoreOptions = [
    { value: 1, label: "1" },
    { value: 1.5, label: "1.5" },
    { value: 2, label: "2" },
    { value: 2.5, label: "2.5" },
    { value: 3, label: "3" },
    { value: 3.5, label: "3.5" },
    { value: 4, label: "4" },
    { value: 4.5, label: "4.5" },
    { value: 5, label: "5" },
  ];

  // Initialize the state as an empty object
  const [selectedScores, setSelectedScores] = useState({});

  // Handler for changing the select value
  const handleSelectChange = (key, value) => {
    setSelectedScores((prevScores) => ({
      ...prevScores,
      [key]: value,
    }));
  };

  console.log(selectedScores, "selectedScores");

  return (
    <div>
      {/*begin::Followers toolbar*/}
      <div className="d-flex flex-wrap flex-stack mb-6 ">
        {/*begin::Title*/}
        {/* <h3 className="text-gray-800 fw-bold my-0">
          Curation Central
          <span className="fs-6 text-gray-500 fw-semibold ms-1">(29)</span>
        </h3> */}

        {/*end::Title*/}
        {/*begin::Controls*/}
        <div className="d-flex my-0 gap-4">
          {/* <Link to="/customers" className="btn btn-primary btn-sm me-2 pt-3">
            Back
          </Link> */}

          {/* Display number of active filters */}
          <div className="d-flex align-items-center ms-3">
            <span className="badge bg-primary">
              <span className="fs-1 fw-bold text-white">
                {bookingData?.length > 0 && bookingData?.length}
              </span>
            </span>
          </div>

          <div className="position-relative me-3">
            <Select
              showSearch
              name="project"
              value={filterData?.project}
              onChange={(value) => {
                setFilterData((prevState) => ({
                  ...prevState,
                  project: value,
                }));
              }}
              placeholder="Select Project"
              className="fs-7 w-150px"
              style={{ width: "100%" }}
              dropdownStyle={{ zIndex: 1100 }}
              size="large"
              options={projectOptions}
              allowClear
              onClear={() =>
                setFilterData((prevState) => ({
                  ...prevState,
                  project: null,
                }))
              }
            />
          </div>

          {/* Status Select */}
          <div className="position-relative">
            <Select
              showSearch
              name="status"
              value={filterData?.status}
              onChange={(value) => {
                setFilterData((prevState) => ({
                  ...prevState,
                  status: value,
                }));
              }}
              placeholder="Select Status"
              className="fs-8 me-3 w-150px"
              dropdownStyle={{ zIndex: 1100 }}
              size="large"
              options={editionsStatusOptions}
              allowClear
              onClear={() =>
                setFilterData((prevState) => ({
                  ...prevState,
                  status: null,
                }))
              }
            />
          </div>

          {/* Edition Select */}
          <div className="position-relative">
            <Select
              showSearch
              name="edition"
              value={filterData?.edition}
              onChange={(value) => {
                setFilterData((prevState) => ({
                  ...prevState,
                  edition: value,
                }));
              }}
              placeholder="Select Edition"
              className="fs-8 me-3 w-150px"
              dropdownStyle={{ zIndex: 1100, width: 250 }}
              size="large"
              options={editionsOptions}
              allowClear
              onClear={() =>
                setFilterData((prevState) => ({
                  ...prevState,
                  edition: null,
                }))
              }
            />
          </div>

          {/* Priority Checkbox */}
          <div className="d-flex align-items-center position-relative me-3">
            <Checkbox
              checked={filterData.priority}
              onChange={(e) => {
                const checked = e.target.checked;
                setFilterData((prevState) => ({
                  ...prevState,
                  priority: checked,
                }));
              }}
            >
              <span className="fs-5 fw-semibold text-gray-800">Priority</span>
            </Checkbox>
          </div>
        </div>
      </div>
      {/*end::Followers toolbar*/}
      {/*begin::Row*/}
      <div className="row g-6 mb-6 g-xl-9 mb-xl-9">
        {/* this is using user data */}
        {/* {userData.map((data) => (
          <div className="col-md-6 col-xxl-4" key={data.uuid}>
            <div className="card hover-elevate-up shadow-sm parent-hover">
              <div
                className="card-body pb-3 card-scroll hover-scroll-x"
                style={{ height: "450px" }}
              >
                <div className="d-flex flex-stack flex-row">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-65px symbol-circle me-5">
                      <img src={data?.photo ?? "/media/img.jpg"} alt="avatar" />
                    </div>
                    <div>
                      <a
                        href="#"
                        className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                      >
                        {data.first_name || "-"} {data.last_name}
                      </a>
                      <div className="fw-semibold text-primary mb-0">
                        {data.date_of_birth || "-"}
                      </div>
                      <a
                        href={`mailto:${data.email}`}
                        className="text-gray-800 fw-bold"
                      >
                        {data.email || "-"}
                      </a>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toast.loading("Loading..");
                      handleStarClick(data?.uuid);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {isStarProfile ? (
                      <i className="ki-duotone ki-star fs-1 text-warning" />
                    ) : (
                      <i className="ki-outline ki-star fs-1" />
                    )}
                  </div>
                </div>
                <div className="separator separator-dashed border-gray-300 my-4" />
                <div>
                  <h5 className="mb-2">Key Information</h5>
                  <div className="row g-1">
                    <div className="col-md-6">
                      <span className="fw-semibold text-gray-800 fs-7">
                        Revenue:{" "}
                        <span className="fw-bolder">
                          {data.user_work[0]?.revenue || "-"}
                        </span>
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span className="fw-semibold text-gray-800 fs-7">
                        Age:{" "}
                        <span className="fw-bolder">
                          {new Date().getFullYear() -
                            new Date(data.date_of_birth).getFullYear() || "-"}
                        </span>
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span className="fw-semibold text-gray-800 fs-7">
                        Designation:{" "}
                        <span className="fw-bolder">
                          {data.user_work[0]?.designation || "-"}
                        </span>
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span className="fw-semibold text-gray-800 fs-7">
                        City:{" "}
                        <span className="fw-bolder">
                          {data.user_profile.city_name || "-"}
                        </span>
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span className="fw-semibold text-gray-800 fs-7">
                        Referred By:{" "}
                        <span className="fw-bolder">
                          {data.user_work[0]?.name || "-"}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="separator separator-dashed border-gray-300 my-4" />
                <div>
                  <h5 className="mb-2">Story</h5>
                  <p className="mb-0">{data.user_profile.bio || "-"}</p>
                </div>
                <div
                  className={`collapse mt-4 ${
                    openCardIds[data.uuid] ? "show" : ""
                  }`}
                  id={`kt_app_sidebar_menu_dashboards_collapse${data.uuid}`}
                >
                  <div>
                    <h5 className="mb-2">Top Reasons to Attend</h5>
                    <p className="mb-0">
                      Placeholder for Top Reasons to Attend
                    </p>
                  </div>
                  <div className="mt-3">
                    <h5 className="mb-2">Want Growth In</h5>
                    <p className="mb-0">Placeholder for Growth Areas</p>
                  </div>
                  <div className="mt-3">
                    <div>
                      <small>
                        Status:{" "}
                        <span
                          // className={`badge badge-light-${
                          //   data.status == 1
                          //     ? "warning"
                          //     : data.status == 2
                          //     ? "danger"
                          //     : data?.status == 3
                          //     ? "success"
                          //     : "dark"
                          // }`}
                          className="badge badge-light-dark mb-2"
                        >
                          {data?.status == 1
                            ? "Pending"
                            : data.status == 2
                            ? "Abandon"
                            : data?.status == 3
                            ? "Approved"
                            : "Attended"}
                        </span>
                      </small>
                    </div>
                    <div>
                      <small>
                        Score:{" "}
                        <span className="badge badge-primary ms-2">
                          {data.score || "-"}
                        </span>
                      </small>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="mb-1">
                      <div
                        title="Change Status"
                        className="fs-7 fw-semibold text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // set_data(item);
                          setIsOpenUserModal(true);
                        }}
                      >
                        Change Status
                      </div>
                    </div>
                    <div className="mb-1">
                      <a
                        // href="#"
                        style={{ cursor: "pointer" }}
                        className="fs-7 fw-semibold"
                      >
                        Go to All Users
                      </a>
                    </div>
                    <div className="mb-1">
                      <a
                        // href="#"
                        style={{ cursor: "pointer" }}
                        className="fs-7 fw-semibold"
                      >
                        Change Insta URL
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-3 mb-4">
                  <a
                    className={`btn btn-flex btn-color-primary d-flex flex-stack fs-base p-0 toggle collapsible ${
                      openCardIds[data.uuid] ? "" : "collapsed"
                    }`}
                    data-bs-toggle="collapse"
                    href={`#kt_app_sidebar_menu_dashboards_collapse${data.uuid}`}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default behavior of the anchor tag
                      handleToggle(data.uuid);
                    }}
                    data-kt-toggle-text="Hide"
                  >
                    <span data-kt-toggle-text-target="true">
                      {openCardIds[data.uuid] ? "Hide" : "Expand"}
                    </span>
                  </a>
                </div>
                <div className="d-flex flex-stack">
                  <div>
                    <span className="badge badge-light-dark badge-lg fs-7 fw-bold px-4 py-2">
                      Suggested Score
                    </span>
                  </div>
                  <div className="d-flex flex-shrink-0 justify-content-end">
                    <a
                      href="#"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                    >
                      <i className="bi bi-check fs-1 text-gray-800" />
                    </a>
                    <a
                      href="#"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                    >
                      <i className="bi bi-whatsapp fs-2 text-success" />
                    </a>
                    <a
                      href="#"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                    >
                      <i className="ki-outline ki-xmr fs-2 text-success" />
                    </a>
                    <a
                      href="#"
                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                    >
                      <i className="ki-outline ki-instagram fs-2 text-danger" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex align-items-center py-4">
                <div className="position-relative w-100">
                  <Select
                    value={selectedScore}
                    onChange={(e) => {
                      setSelectedScore(e);
                    }}
                    placeholder="Select Score"
                    className="fs-7 w-100"
                    style={{ width: "100%" }}
                    size="large"
                    options={scoreOptions}
                  ></Select>
                </div>
                <button
                  className="btn btn-primary btn-sm ms-4"
                  onClick={() => {
                    toast.loading("Changing Score...");
                    onSubmitUser(data?.uuid);
                  }}
                >
                  Curate
                </button>
                <button
                  className="btn btn-primary btn-sm ms-4"
                  style={{ whiteSpace: "nowrap", width: "auto" }}
                  onClick={() => {
                    setViewNotesModal(!viewNotesModaL);
                  }}
                >
                  {curationNote && userData[0]?.is_curation_note
                    ? "View Notes"
                    : "Add Notes"}
                </button>
              </div>
            </div>
          </div>
        ))} */}
      </div>

      <div className="row g-6 mb-6 g-xl-9 mb-xl-9">
        {bookingDataLoading ? (
          <div className="table-responsive">
            <Loader cols={1} width={550} />
          </div>
        ) : (
          <>
            {bookingData?.length > 0 ? (
              bookingData?.map((data, i) => (
                <div className="col-md-6 col-xxl-4" key={data.user.uuid}>
                  <div className="card hover-elevate-up shadow-sm parent-hover">
                    <div
                      className="card-body pb-3 card-scroll hover-scroll-x"
                      style={{ height: "450px" }}
                    >
                      <div className="d-flex flex-stack flex-row">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-65px symbol-circle me-5">
                            <img
                              src={data.user.photo || "/media/img.jpg"}
                              alt="avatar"
                            />
                          </div>
                          <div>
                            <a
                              href="#"
                              className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                            >
                              {data.user.first_name || "-"}{" "}
                              {data.user.last_name}
                            </a>
                            <div className="fw-semibold text-primary mb-0">
                              {dayjs().diff(
                                dayjs(data.user.date_joined),
                                "hour"
                              )}{" "}
                              hours ago
                              {/* {data.user.date_of_birth || "-"} */}
                            </div>
                            <a
                              href={`mailto:${data.user.email}`}
                              className="text-gray-800 fw-bold"
                            >
                              {data.user.email || "-"}
                            </a>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-center gap-3">
                          <div
                            onClick={() => {
                              toast.loading("Loading..");
                              handleStarClick(
                                data.user.uuid,
                                data.user?.user_profile?.is_star_profile
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {data.user?.user_profile?.is_star_profile ? (
                              <i className="ki-duotone ki-star fs-1 text-warning" />
                            ) : (
                              <i className="ki-outline ki-star fs-1" />
                            )}
                          </div>
                          <div>
                            {i == 1 && (
                              <span className="badge badge-outline badge-danger mt-0">
                                Overdue
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="separator separator-dashed border-gray-300 my-4" />
                      <div>
                        <h5 className="mb-2">Key Information</h5>
                        <div className="row g-1">
                          <div className="col-md-6">
                            <span className="fw-semibold text-gray-800 fs-7">
                              Edition:{" "}
                              <span className="fw-bolder">
                                {" "}
                                {data.edition_name || "-"}
                              </span>{" "}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span className="fw-semibold text-gray-800 fs-7">
                              Project:{" "}
                              <span className="fw-bolder">
                                {data.project_name || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="col-md-6">
                            <span className="fw-semibold text-gray-800 fs-7">
                              Revenue:{" "}
                              <span className="fw-bolder">
                                {" "}
                                {data.user.user_work[0]?.revenue_label || "-"}
                              </span>{" "}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span className="fw-semibold text-gray-800 fs-7">
                              Age:{" "}
                              <span className="fw-bolder">
                                {new Date().getFullYear() -
                                  new Date(
                                    data.user.date_of_birth
                                  ).getFullYear() || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="col-md-6">
                            <span className="fw-semibold text-gray-800 fs-7">
                              City:{" "}
                              <span className="fw-bolder">
                                {data.user.user_profile?.city_name || "-"}
                              </span>{" "}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span className="fw-semibold text-gray-800 fs-7">
                              Referred By:{" "}
                              <span className="fw-bolder">{"-"}</span>{" "}
                            </span>
                          </div>
                          <div className="col-md-12 mt-2">
                            <span className="fw-semibold text-gray-800 fs-7">
                              Designation:{" "}
                              <span className="fw-bolder">
                                {data.user.user_work[0]?.designation || "-"}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="separator separator-dashed border-gray-300 my-4" />
                      <div>
                        <h5 className="mb-2">Story</h5>
                        <p className="mb-0">{data.intentions || "-"}</p>{" "}
                        {/* Using `intentions` as a story */}
                      </div>
                      <div
                        className={`collapse mt-4 ${
                          openCardIds[data.user.uuid] ? "show" : ""
                        }`}
                        id={`kt_app_sidebar_menu_dashboards_collapse${data.user.uuid}`}
                      >
                        <div>
                          <h5 className="mb-2">Top Reasons to Attend</h5>
                          <p className="mb-0">
                            {data.top_priority_labels.join(", ") || "-"}
                          </p>
                        </div>
                        <div className="mt-3">
                          <h5 className="mb-2">Want Growth In</h5>
                          <p className="mb-0">
                            {data.growth_areas_labels.join(", ") || "-"}
                          </p>
                        </div>
                        <div className="mt-3">
                          <div>
                            <small>
                              Status:{" "}
                              <span
                                className={`badge badge-light-${
                                  data.status == 1
                                    ? "warning"
                                    : data.status == 2
                                    ? "danger"
                                    : data.status == 3
                                    ? "success"
                                    : "dark"
                                }`}
                              >
                                {data.status_labels || "Unknown"}
                              </span>
                            </small>
                          </div>
                          <div>
                            <small>
                              Score:{" "}
                              <span className="badge badge-primary ms-2">
                                {data.user.score || "-"}
                              </span>
                            </small>
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="mb-1">
                            <div
                              title="Change Status"
                              className="fs-7 fw-semibold text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setStatusModalData({
                                  // uuid: data.user.uuid,
                                  uuid: data.id, //Booking Id
                                  status: data.status,
                                });

                                setIsOpenUserModal(true);
                              }}
                            >
                              Change Status
                            </div>
                          </div>
                          <div className="mb-1">
                            <a
                              style={{ cursor: "pointer" }}
                              className="fs-7 fw-semibold"
                            >
                              Go to All Users
                            </a>
                          </div>
                          <div className="mb-1">
                            <a
                              style={{ cursor: "pointer" }}
                              className="fs-7 fw-semibold"
                            >
                              Change Insta URL
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 mb-4">
                        <a
                          className={`btn btn-flex btn-color-primary d-flex flex-stack fs-base p-0 toggle collapsible ${
                            openCardIds[data.user.uuid] ? "" : "collapsed"
                          }`}
                          data-bs-toggle="collapse"
                          href={`#kt_app_sidebar_menu_dashboards_collapse${data.user.uuid}`}
                          onClick={(e) => {
                            e.preventDefault(); // Prevent default behavior of the anchor tag
                            handleToggle(data.user.uuid);
                          }}
                          data-kt-toggle-text="Hide"
                        >
                          <span data-kt-toggle-text-target="true">
                            {openCardIds[data.user.uuid] ? "Hide" : "Expand"}
                          </span>
                        </a>
                      </div>
                      <div className="d-flex flex-stack">
                        <div>
                          <span className="badge badge-light-dark badge-lg fs-7 fw-bold px-4 py-2">
                            Suggested Score
                          </span>
                        </div>
                        <div className="d-flex flex-shrink-0 justify-content-end">
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="bi bi-check fs-1 text-gray-800" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="bi bi-whatsapp fs-2 text-success" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="ki-outline ki-xmr fs-2 text-success" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                          >
                            <i className="ki-outline ki-instagram fs-2 text-danger" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center py-4">
                      <div className="position-relative w-100">
                        <Select
                          // value={data?.user?.score || 0}
                          // // value={selectedScore}
                          // onChange={(e) => {
                          //   setSelectedScore(e);
                          // }}
                          value={selectedScores[data.user.uuid] || 0} // Use the state for each dropdown
                          onChange={(value) =>
                            handleSelectChange(data.user.uuid, value)
                          } // Update the state for the specific dropdown
                          placeholder="Select Score"
                          className="fs-7 w-100"
                          style={{ width: "100%" }}
                          size="large"
                          options={scoreOptions}
                        ></Select>
                      </div>
                      <button
                        className="btn btn-primary btn-sm ms-4"
                        onClick={() => {
                          toast.loading("Changing Score...");
                          onSubmitUser(data.user.uuid);
                        }}
                      >
                        Curate
                      </button>
                      <button
                        className="btn btn-primary btn-sm ms-4"
                        style={{ whiteSpace: "nowrap", width: "auto" }}
                        onClick={() => {
                          setViewNotesModalData({
                            userId: data.user.uuid,
                            is_curation_note: data?.user?.is_curation_note,
                          });

                          // getCurationNote(data.user.uuid);
                          setViewNotesModal(!viewNotesModaL);
                        }}
                      >
                        {data?.user?.is_curation_note
                          ? "View Notes"
                          : "Add Notes"}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="row g-5 mb-0 gx-md-10">
                <div className="col-md-12 text-center">
                  <div className="mt-0">
                    <img
                      src="/media/no-data-found.png"
                      className="mw-25"
                      alt="No Data Found"
                    />
                  </div>
                  <h1 className="mt-4">No Data Found</h1>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isOpenUserModal && (
        <StatusModal
          show={isOpenUserModal}
          statusList={statusList}
          onHide={() => {
            setIsOpenUserModal(false);
            // setLoading(true);
            // getData();

            setBookingDataLoading(true);
            getBookingData();
          }}
          data={statusModalData}
          // data={{
          //   uuid: id,
          //   status: userData[0]?.status,
          // }}
        />
      )}

      {viewNotesModaL && (
        <ViewNotesModal
          show={viewNotesModaL}
          curationNoteOptions={curationNoteOptions}
          curationNote={curationNote}
          onHide={() => {
            setViewNotesModal(false);
            // setLoading(true);
            // getData();

            setBookingDataLoading(true);
            getBookingData();
          }}
          data={viewNotesModalData}
          // data={{
          //   uuid: id,
          //   is_curation_note: userData[0]?.is_curation_note,
          // }}
        />
      )}
    </div>
  );
}
