import React, { useEffect, useState } from "react";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { Loader } from "../../../../components";

const data1 = {
  vibe: [{ id: 1, label: "Fun, Weird & Quirky" }],
  special_note: "Qui veritatis et est cum rem doloribus.",
  edition: "66e947c95d2d24c2274b73e1",
  foods: [
    { id: 1, label: "Veg Only" },
    { id: 2, label: "Jain" },
  ],
  smoking: { id: 2, label: "I smoke occasionally if the vibe is right" },
  drinking: { id: 1, label: "I don't drink at all" },
  sleeping: { id: 1, label: "I snore sometimes" },
  wakeup: { id: 1, label: "A night watchman" },
  contribute: "Nemo sit ut eligendi.",
};

const Preferences = ({ edition_id, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetchData(
      `customer/api/preference/?edition=${edition_id || ""}&user_id=${id}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        console.log(res, "res");
        if (res?.is_blank || res?.special_note === "") {
          setData({});
        } else {
          const data = formatData(res);
          console.log(Object.entries(data).length === 0, "value");
          console.log(data, "formatData");
          setData(data);
        }
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  // Function to format the data
  const formatData = (data) => {
    // Check if the data object is empty
    if (Object.keys(data).length === 0) {
      return {}; // Return an empty object if data is empty
    }

    // Define an array of habit mappings
    const habitMappings = [
      { title: "Smoking", key: "smoking" },
      { title: "Drinking", key: "drinking" },
      { title: "Sleeping Habit", key: "sleeping" },
      { title: "Wakeup Habit", key: "wakeup" },
    ];

    // Use map to build the habits array dynamically
    const habits = habitMappings.reduce((acc, { title, key }) => {
      if (data[key]) {
        acc.push({ title, label: data[key].label });
      }
      return acc;
    }, []);

    // Return the new formatted data object
    return {
      vibe: data.vibe || [], // Safely handle potential undefined properties
      special_note: data.special_note || "",
      edition: data.edition || "",
      foods: data.foods || [],
      habits: habits,
      contribute: data.contribute || "",
    };
  };

  // Format the data
  const formattedData = formatData(data1);

  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Preferences</h2>
        </div>

        <div className="card-toolbar"></div>
      </div>

      <div className="card-body py-2 pb-7">
        {/*begin::Table container*/}
        {loading ? (
          <div className="table-responsive">
            <Loader cols={1} width={250} />
          </div>
        ) : (
          <div>
            {Object.entries(data).length === 0 ? ( // Check if data object is empty
              <div className="row g-5 mb-0 gx-md-10">
                <div className="col-md-12 text-center">
                  <div className="mt-0">
                    <img
                      src="/media/no-data-found.png"
                      className="mw-25"
                      alt="No Data Found"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-7">
                  <i className="ki-duotone ki-information-5 fs-2tx text-primary me-4">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-bold">
                      <h4 className="text-gray-900 fw-bolder">Special Note</h4>
                      <div className="fs-6 text-gray-700">
                        {data?.special_note}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-7">
                  <h4 className="fw-bold mb-3">Food Preferences</h4>
                  <div className="d-flex flex-wrap">
                    {data?.foods?.map((food) => (
                      <span
                        key={food.id}
                        className="badge badge-light-success fs-7 m-1"
                      >
                        {food.label}
                      </span>
                    )) || <span>No food preferences available</span>}
                  </div>
                </div>

                <div className="mb-10">
                  <h4 className="fw-bold mb-5">Habits</h4>
                  <div className="row g-5">
                    {data?.habits?.map((habit, index) => (
                      <div className="col-md-6" key={index}>
                        <div className="d-flex flex-stack border border-dashed border-gray-300 p-6 rounded mb-5">
                          <div className="d-flex align-items-center me-2">
                            <div className="flex-grow-1">
                              <a
                                href="#"
                                className="text-dark fw-bolder text-hover-primary fs-4"
                              >
                                {habit.title}
                              </a>
                              <span className="text-muted fw-bold d-block fs-7">
                                {habit.label}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )) || <div>No habits available</div>}
                  </div>
                </div>

                <div className="mb-7">
                  <h4 className="fw-bold mb-3">Contribution</h4>
                  <div className="bg-light-warning rounded border-warning border border-dashed p-6 d-flex align-items-center">
                    <i className="ki-duotone ki-handcart fs-2tx text-warning me-4">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="fs-6 text-gray-700">
                      {data?.contribute ||
                        "No contribution information available"}
                    </span>
                  </div>
                </div>

                <div className="mb-7">
                  <h4 className="fw-bold mb-3">Vibe</h4>
                  <div className="d-flex align-items-center bg-light-danger rounded p-5">
                    <i className="ki-duotone ki-heart fs-1 text-danger me-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <div className="fs-6 text-gray-700 me-2">
                      {data?.vibe?.[0]?.label ||
                        "No vibe information available"}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Preferences;
