import React, { useEffect, useState } from "react";
import { Loader, Pagination } from "../../../components";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import circularProgressBar from "../../../components/CirculerProgressBar";
import { Select } from "antd";
import { LIMITS } from "../../../config";

const paymentTableData = [
  {
    name: "Alisha Mangla",
    date: "2024-01-15",
    duration: "4.0 months",
    amount: "₹98000",
    destination: "BHK Bart 6",
    batch: "6/17",
    batchName: "BHK Alisha",
    rating: 3.4,
    pendingAmount: "₹70000",
    place: "Ahmedabad",
  },
  {
    name: "Rohan Sharma",
    date: "2024-02-10",
    duration: "3.5 months",
    amount: "₹75000",
    destination: "BHK Bart 4",
    batch: "5/15",
    batchName: "BHK Rohan",
    rating: 4.0,
    pendingAmount: "₹35000",
    place: "Mumbai",
  },
  {
    name: "Sneha Verma",
    date: "2024-03-05",
    duration: "5.0 months",
    amount: "₹105000",
    destination: "BHK Bart 3",
    batch: "7/18",
    batchName: "BHK Sneha",
    rating: 5,
    pendingAmount: "₹25000",
    place: "Bangalore",
  },
];

const refundTableData = [
  {
    name: "Prarthana",
    reason: "Cannot join in for the edition, too expensive",
    amount: 15000,
    emailPhone: "abc.1234@gmail.com",
    date: "Feb 9, 2024",
  },
  {
    name: "Prarthana 33",
    reason: "Cannot join in for the edition, too expensive",
    amount: 35000,
    emailPhone: "cde.1234@gmail.com",
    date: "Feb 29, 2024",
  },
];

const salesInsightData = [
  {
    name: "Shashank",
    appConfirm: "199+0.3",
    appContribution: 0,
    insideConfirm: "199+0.3",
    emailsSent: 3,
    callDonePlusBooked: "",
    edition: "BCK Future Editions",
    paymentDate: "",
  },
];

const singleRoomAddonsData = [
  {
    name: "NikitaInVicky's",
    refundedOn: "BHK Sri Lanka 4",
    amount: "INR 35000",
    emailPhone: "vikkys.nikkaita99@gmail.com",
    date: "Sep 23, 2023",
  },
];

export default function Payments() {
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(LIMITS[0]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [editionsList, setEditionsList] = useState([]);

  const [filterData, setFilterData] = useState({
    resource_manager: null,
    edition: null,
  });

  useEffect(() => {
    getData();
  }, [filterData]);

  useEffect(() => {
    getEditions();
  }, []);

  const getData = () => {
    setPaymentDataLoading(true);

    fetchData(
      `api/order/list/?edition=${filterData?.edition || ""}&curator_id=${
        filterData?.resource_manager || ""
      }`,
      "get"
    ).then((res) => {
      setPaymentDataLoading(false);
      if (res.success) {
        setTotalRecords(res?.count);
        setPaymentData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };
  return (
    <div>
      <div className="row gy-5 g-xl-5">
        {/* table 1 */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Payment Details</h3>
              <div className="card-toolbar gap-2">
                <div className="position-relative ">
                  <Select
                    value={filterData?.edition}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setFilterDataValue("edition", value)}
                    placeholder="Select Edition"
                    style={{ width: "200px" }}
                    options={editionsList}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("edition", null);
                    }}
                    size="large"
                  />
                </div>

                {/* <div className="position-relative ">
                  <Select
                    value={filterData?.resource_manager}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) =>
                      setFilterDataValue("resource_manager", value)
                    }
                    placeholder="Select Resource Manager"
                    style={{ width: "200px" }}
                    // options={editionsList}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("resource_manager", null);
                    }}
                    size="large"
                  />
                </div> */}
              </div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {paymentDataLoading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {paymentData?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-75px text-center">#</th>
                              <th className="min-w-140px">Name</th>
                              <th className="min-w-120px">Duration</th>
                              <th className="min-w-105px">Total Amount</th>
                              <th className="min-w-105px">Edition</th>
                              {/* <th className="min-w-60px">Batch</th>
                     <th className="min-w-105px">Batch Name</th> */}
                              <th className="min-w-60px">Resource Manager</th>
                              <th className="min-w-50px text-center">Rating</th>
                              <th className="min-w-90px">Pending Amount</th>
                              {/* <th className="min-w-7px">Place</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {paymentData?.length > 0 ? (
                              paymentData.map((item, i) => {
                                console.log(item, "item");
                                return (
                                  <tr className="bg-light" key={i}>
                                    <td className="bdr-left-user text-center">
                                      {i + 1}
                                    </td>
                                    <td>
                                      {item?.user_details &&
                                        Object.entries(item?.user_details)
                                          .length > 0 && (
                                          <>
                                            <div className="d-flex align-items-center">
                                              <div className="symbol symbol-circle symbol-45px overflow-hidden me-3">
                                                {item?.user_details?.photo ? (
                                                  <img
                                                    src={
                                                      item?.user_details?.photo
                                                    }
                                                    alt={`${item?.user_details?.first_name}`}
                                                    className="rounded-circle"
                                                  />
                                                ) : (
                                                  <div className="symbol symbol-circle symbol-45px mr-3">
                                                    <span
                                                      className="symbol-label fs-2 text-primary bg-light-primary"
                                                      style={{
                                                        border:
                                                          "1px dashed #7843e6",
                                                        borderRadius: "50%",
                                                      }}
                                                    >
                                                      {item?.user_details?.first_name
                                                        ?.charAt(0)
                                                        .toUpperCase()}
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="d-flex flex-column">
                                                <a
                                                  href="#"
                                                  className="text-primary mb-1 fw-bolder gotham_black"
                                                >
                                                  {`${item?.user_details?.first_name} ${item?.user_details?.last_name}`}
                                                </a>
                                                <span className="text-gray-600 fw-semibold">
                                                  {dayjs(
                                                    item?.created_at
                                                  ).format("MMM D, YYYY")}
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                    </td>
                                    <td>
                                      {dayjs(item?.user_details?.end_date).diff(
                                        dayjs(item?.user_details?.start_date),
                                        "month"
                                      )}
                                    </td>
                                    <td className="fw-semibold fs-6">
                                      {item.total_amount}
                                    </td>

                                    <td>{item?.edition?.name}</td>
                                    <td>
                                      {item?.user_details &&
                                        Object.entries(item?.user_details)
                                          .length > 0 &&
                                        item?.user_details?.curator
                                          ?.curator_first_name}
                                    </td>
                                    <td>
                                      {item?.user_details &&
                                        Object.entries(item?.user_details)
                                          .length > 0 &&
                                        circularProgressBar(
                                          item?.user_details?.score,
                                          35,
                                          3
                                        )}
                                    </td>
                                    <td className="fw-semibold fs-6">
                                      {String(item?.remaining_amount)}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="row g-5 mb-0 gx-md-10">
                                <div className="col-md-12 text-center">
                                  <div className="mt-0">
                                    <img
                                      src="/media/no-data-found.png"
                                      className="mw-25"
                                      alt="No Data Found"
                                    />
                                  </div>
                                  <h1 className="mt-4">No Data Found</h1>
                                </div>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        data={paymentData}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setPaymentDataLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>

        {/* table 2 */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Refund Requests</h3>
              <div className="card-toolbar gap-2"></div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-180px">Name</th>
                          <th className="min-w-120px">Reason</th>
                          <th className="min-w-125px">Amount</th>
                          <th className="min-w-125px">Email/Phone</th>
                          <th className="min-w-100px">Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {refundTableData?.length > 0
                          ? refundTableData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.reason}</td>
                                  <td className="fw-semibold fs-6">
                                    {item.amount}
                                  </td>
                                  <td>{item.emailPhone}</td>
                                  <td>{item.date}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={refundTableData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* table 3 */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Sales Insights </h3>
              <div className="card-toolbar gap-2">
                <div></div>
              </div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-180px">Name</th>
                          <th className="min-w-120px">App + Confirm</th>
                          <th className="min-w-125px">App + Contribution</th>
                          <th className="min-w-125px">Inside + Confirm</th>
                          <th className="min-w-100px">Emails Sent</th>
                          <th className="min-w-175px">Call Done + Booked</th>
                          <th className="min-w-90px">Edition</th>
                          <th className="min-w-90px">Payment Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {salesInsightData?.length > 0
                          ? salesInsightData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.appConfirm}</td>
                                  <td>{item.appContribution}</td>
                                  <td>{item.insideConfirm}</td>
                                  <td>{item.emailsSent}</td>
                                  <td>{item.callDonePlusBooked}</td>
                                  <td>{item.edition}</td>
                                  <td>{item.paymentDate}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={salesInsightData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* table 4 */}
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <h3 className="card-title">Single Room Add-ons</h3>

              <div className="card-toolbar gap-2"></div>
            </div>

            <div className="card-body py-2">
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div className="main-box clearfix">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-dark bg-white">
                          <th className="min-w-75px text-center">#</th>
                          <th className="min-w-180px">Name</th>
                          <th className="min-w-120px">Refunded On</th>
                          <th className="min-w-125px">Amount</th>
                          <th className="min-w-125px">Email/Phone</th>
                          <th className="min-w-100px">Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {singleRoomAddonsData?.length > 0
                          ? singleRoomAddonsData?.map((item, i) => {
                              return (
                                <tr className="bg-light" key={i}>
                                  <td className="bdr-left-user text-center">
                                    {i + 1}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.refundedOn}</td>
                                  <td>{item.amount}</td>
                                  <td>{item.emailPhone}</td>
                                  <td>{item.date}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={10}
                    data={singleRoomAddonsData}
                    activePage={1}
                    totalRecords={22}
                    // onChange={(pageNumber) => {
                    //   setLoading(true);
                    //   setActivePage(pageNumber);
                    // }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
