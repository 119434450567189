import React, { useState } from "react";
import { Timeline, Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

const VisaJourneyTimeline = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noteValue, setNoteValue] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (noteValue.trim()) {
      console.log("New note:", noteValue);
      // Here you would typically add the new note to your data
      setNoteValue("");
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNoteValue("");
  };

  return (
    <div className="card card-custom">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <Timeline
            items={[
              {
                color: "green",
                children: (
                  <>
                    <p className="font-weight-bold">Visa Journey Updated</p>
                    <p className="text-muted">3:00 pm - Dec 11, 2023</p>
                    <Button
                      type="link"
                      onClick={showModal}
                      className="p-0 text-primary"
                    >
                      Add Note
                    </Button>
                  </>
                ),
              },
              {
                color: "green",
                children: (
                  <>
                    <p className="font-weight-bold">Visa Call Done</p>
                    <p className="text-muted">1:30 pm - Dec 11, 2023</p>
                    <Button
                      type="link"
                      onClick={showModal}
                      className="p-0 text-primary"
                    >
                      Add Note
                    </Button>
                  </>
                ),
              },
            ]}
          />
          <div onClick={showModal} className="btn btn-primary font-weight-bold">
            Add Note
          </div>
        </div>

        <Modal
          title="Add Note"
          open={isModalVisible}
          footer={
            <>
              <button
                type="button"
                className="btn btn-light-danger fs-7 me-3"
                onClick={handleCancel}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary fs-7"
                onClick={() => {
                  toast.loading("Adding Curation Note");
                  handleOk();
                }}
                // disabled={sending}
              >
                Submit
                {false && (
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                )}
              </button>
            </>
          }
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="row mt-5">
            <label
              htmlFor="noteInput"
              className="form-label required fs-6 fw-bold mb-2"
            >
              Note
            </label>
            <textarea
              id="noteInput"
              className="form-control "
              rows={4}
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
              placeholder="Enter your note here"
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default VisaJourneyTimeline;
