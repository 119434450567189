import React, { useState } from "react";
import { MaxSpotsModal, SettingsModal, RedEditionModal } from "./modals";
import { Link, useNavigate } from "react-router-dom";
import { routesList } from "../../../config";
import circularProgressBar from "../../../components/CirculerProgressBar";
import { Popover, Progress, Tag } from "antd";
import dayjs from "dayjs";
import moment from "moment";

export default function Table({ data }) {
  const navigate = useNavigate();
  const [settingsModal, setSettingsModal] = useState(false);
  const [maxSpotsModal, setMaxSpotsModal] = useState(false);
  const [redEditionModal, setRedEditionModal] = useState(false);

  console.log(data, "data");

  const handleSettingsModal = () => {
    // logic for opening settings modal
  };

  const handleMaxSpotsModal = () => {
    // logic for opening max spots modal
  };

  const handleRedEditionModal = () => {
    // logic for opening red edition modal
  };

  const formatCost = (cost) => {
    if (cost >= 10000000) {
      return (cost / 10000000).toFixed(2) + " Cr"; // For Crores
    } else if (cost >= 100000) {
      return (cost / 100000).toFixed(2) + " L"; // For Lakhs
    } else if (cost >= 1000) {
      return (cost / 1000).toFixed(2) + " K"; // For Thousands
    } else {
      return cost.toLocaleString(); // If less than 1000, display normally
    }
  };

  return (
    <div>
      {/*begin::Table*/}
      <div className="table-responsive tbl-sales">
        <table className="table align-middle gs-3 border-top-d">
          <thead>
            <tr className="fw-bolder text-gray-900 bg-light-primary w-100">
              <th className="min-w-200px">Edition</th>
              <th className="min-w-100px">Price</th>
              <th className="min-w-100px">Vibe score</th>
              <th className="min-w-100px">Tickets Sold</th>
              <th className="min-w-80px">Conv. %</th>
              <th className="min-w-80px">Payments</th>
              <th className="min-w-60px">Prefs</th>
              <th className="min-w-60px">Counts</th>
              <th className="min-w-100px">Open Loops</th>
              <th className="min-w-80px">T-60</th>
              <th className="min-w-80px">Avg. Age</th>
              <th className="min-w-150px text-center">Control</th>
            </tr>
          </thead>
          <tbody className="fw-semibold fs-7">
            {data.map((item) => {
              const startDate = moment(item.start_date).utc();
              const currentDate = moment().utc();
              const dateDifference = currentDate.diff(startDate, "days");
              console.log(dateDifference, "dateDifference");
              const isHighlighted = dateDifference > -15;

              let strokeColor;

              // Check if the start date is less than the current date
              if (startDate.isBefore(currentDate)) {
                strokeColor = "#5eb736"; // Green
              } else if (item?.sold_count > item?.max_spots) {
                strokeColor = "#e22e23"; // Red
              } else if (item?.status == "2") {
                strokeColor = "#808080"; // Gray
              } else {
                strokeColor = "#FFBF00"; // Yellow (default)
              }

              return (
                <>
                  <tr
                    key={item.id}
                    style={{
                      backgroundColor: isHighlighted
                        ? "#f2eaff"
                        : "transparent", // Highlight if condition met
                    }}
                  >
                    <td>
                      <div
                        className="d-flex align-items-center"
                        onClick={() => {
                          // navigate("/bookings/bookings-page");
                          // setRedEditionModal(!redEditionModal);
                        }}
                      >
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3 cursor-pointer">
                          {item?.hero_image ? (
                            <img src={item?.hero_image} alt="" width={60} />
                          ) : (
                            <div class="symbol symbol-circle symbol-50px mr-3">
                              <span
                                class="symbol-label fs-2 text-primary bg-light-primary"
                                style={{
                                  border: "1px dashed #7843e6",
                                  borderRadius: "50%",
                                }}
                              >
                                {item?.name?.charAt(0).toUpperCase()}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="d-flex flex-column">
                          <Link
                            to={`${routesList["bookings"].path.replace(
                              ":id",
                              item.id
                            )}`}
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6"
                          >
                            {item.name || "-"}
                          </Link>

                          {/* <a
                            href="#"
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6"
                          >
                            {item.name || "-"}
                          </a> */}
                          <div className="text-gray-500">
                            {moment(item.start_date)
                              .utc()
                              .format("MMM D, YYYY")}
                            {item?.status == "2" && (
                              <Tag color="default">Closed</Tag>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span className="fw-bold fs-6 text-gray-900">
                          ₹{formatCost(item?.cost)}
                        </span>
                        <br />
                        <span className="fs-8  text-gray-500">Avg :</span>
                        <span className="fw-semibold fs-7 text-gray-700">
                          {formatCost(item?.avg_selling_price)}
                        </span>
                        <br />
                        <span className="fs-8  text-gray-500">Adv :</span>
                        <span className="fw-semibold fs-7 text-gray-700">
                          {item?.advance_percentage}%
                        </span>
                      </div>
                    </td>
                    <td>
                      <span>
                        {circularProgressBar(item.vibe_score || 0, 35, 3)}
                      </span>
                    </td>
                    <td>{item.sold_count}</td>
                    <td>{item.conv}%</td>
                    <td>{item.payment_count}</td>
                    <td>{item.preference_count}</td>
                    <td>
                      <Popover
                        content={
                          <div>
                            <p className="d-flex justify-content-between fw-bold text-gray-600 fs-7">
                              Full Payments Pending:
                              <span className="text-gray-800">
                                {item.sold_count - item.full_payments_count}
                              </span>
                            </p>
                            <p className="d-flex justify-content-between fw-bold text-gray-600 fs-7">
                              Preferences Pending:
                              <span className="text-gray-800">
                                {item.sold_count - item.preference_count}
                              </span>
                            </p>
                            <p className="d-flex justify-content-between fw-bold text-gray-600 fs-7">
                              Onboarding Pending:
                              <span className="text-gray-800">
                                {item.sold_count - item.onboarding_count || "-"}
                              </span>
                            </p>
                            <p className="d-flex justify-content-between fw-bold text-gray-600 fs-7">
                              Visa Pending:
                              <span className="text-gray-800">
                                {item.sold_count - item.visa_count}
                              </span>
                            </p>
                            <p className="d-flex justify-content-between fw-bold text-gray-600 fs-7">
                              Flight Pending:
                              <span className="text-gray-800">
                                {item.sold_count - item.flight_count}
                              </span>
                            </p>
                          </div>
                        }
                        title="Pending Counts"
                      >
                        <Tag color="default">i</Tag>
                      </Popover>
                    </td>

                    <td>{item.open_loop || "0"}</td>
                    <td>
                      <span className="text-gray-800 fw-bold fs-6">
                        {item.start_date
                          ? moment(item.start_date)
                              .utc()
                              .subtract(60, "days")
                              .format("DD MMM")
                          : "-"}
                      </span>
                    </td>

                    <td>{item.avg_age}</td>

                    <td>
                      <div className="d-flex align-items-center mb-2">
                        <button
                          className="btn bg-gray-200 btn-sm me-1 d-flex"
                          style={{ padding: "4px 6px" }}
                        >
                          M{" "}
                          <span className="badge badge-primary ms-2">
                            {item?.gender_ratio ? item?.gender_ratio?.M : "0"}
                          </span>
                        </button>
                        <button
                          className="btn bg-gray-200 btn-sm me-1 d-flex"
                          style={{ padding: "4px 6px" }}
                        >
                          F{" "}
                          <span className="badge badge-primary ms-2">
                            {item?.gender_ratio ? item?.gender_ratio?.F : "0"}
                          </span>
                        </button>
                        <button
                          className="btn bg-gray-200 btn-sm me-3 d-flex"
                          style={{ padding: "4px 6px" }}
                        >
                          O{" "}
                          <span className="badge badge-primary ms-2">
                            {item?.gender_ratio ? item?.gender_ratio?.O : "0"}
                          </span>
                        </button>
                        <div>Ratio: 0:0</div>
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            setSettingsModal(!settingsModal);
                          }}
                        >
                          <i className="ki-outline ki-gear text-gray-800 ms-3 fs-2" />
                        </span>
                        <Link to={`/manage/editions/handle/${item.id}`}>
                          <i className="ki-outline ki-pencil text-primary ms-3 fs-3" />
                        </Link>
                      </div>

                      <div>
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            setMaxSpotsModal(!maxSpotsModal);
                          }}
                        >
                          <span className="badge badge-outline badge-primary mt-0">
                            Max Spots:{" "}
                            <span className="fw-bolder text-gray-800 ms-1">
                              {item?.max_spots || "-"}
                            </span>
                          </span>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr
                  // style={{
                  //   backgroundColor: isHighlighted
                  //     ? "#f2eaff"
                  //     : "transparent", // Highlight if condition met
                  // }}
                  >
                    <td colSpan={12} style={{ padding: "0px" }}>
                      <Progress
                        percent={item.progress_bar || 0}
                        strokeColor={strokeColor}
                        trailColor="#e0e0e0"
                        percentPosition={{
                          align: "end",
                          type: "outer",
                        }}
                        size={{ height: 8 }}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      {/*end::Table*/}

      <SettingsModal
        show={settingsModal}
        onHide={() => {
          setSettingsModal(false);
        }}
      />
      <MaxSpotsModal
        show={maxSpotsModal}
        onHide={() => {
          setMaxSpotsModal(false);
        }}
      />
      <RedEditionModal
        show={redEditionModal}
        onHide={() => {
          setRedEditionModal(false);
        }}
      />
    </div>
  );
}
