import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LIMITS, routesList } from "../../../config";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Loader, Pagination } from "../../../components";
import { Popover, Select, Tooltip } from "antd";
import BookingsFilterDrawer from "./drawer/bookings-filters";

const dummyData = [
  {
    id: 1,
    edition_name: "Edition A",
    top_priority_labels: ["Priority 1", "Priority 2", "Priority 3"],
    growth_areas_labels: ["Growth Area 1", "Growth Area 2"],
    status: 3, // Approved
    status_labels: "Approved",
    intentions: "Expand business reach",
    payment_date: "2021-04-20",
    resource_manager: "John Doe",
    user: {
      first_name: "John",
      last_name: "Doe",
      date_of_birth: "1988-04-20",
      photo: "/media/users/john-doe.png",
      user_work: [
        {
          designation: "Software Engineer",
        },
      ],
      user_profile: {
        instagram_url: "https://instagram.com/johndoe",
        linkedin_url: "https://linkedin.com/in/johndoe",
      },
    },
  },
  {
    id: 2,
    edition_name: "Edition B",
    top_priority_labels: ["Priority A", "Priority B"],
    growth_areas_labels: ["Growth Area A"],
    status: 2, // Abandon
    status_labels: "Abandon",
    intentions: "Increase market share",
    payment_date: "2021-04-20",
    resource_manager: "John Doe",

    user: {
      first_name: "Jane",
      last_name: "Smith",
      date_of_birth: "1992-08-15",
      photo: "/media/users/jane-smith.png",
      user_work: [
        {
          designation: "Marketing Manager",
        },
      ],
      user_profile: {
        instagram_url: "https://instagram.com/janesmith",
        linkedin_url: "https://linkedin.com/in/janesmith",
      },
    },
  },
  {
    id: 3,
    edition_name: "Edition C",
    top_priority_labels: ["Priority X", "Priority Y", "Priority Z"],
    growth_areas_labels: ["Growth Area X", "Growth Area Y", "Growth Area Z"],
    status: 1, // Pending
    status_labels: "Pending",
    intentions: "Develop new products",
    payment_date: "2021-04-20",
    resource_manager: "John Doe",
    user: {
      first_name: "Alice",
      last_name: "Johnson",
      date_of_birth: "1985-11-05",
      photo: "/media/users/alice-johnson.png",
      user_work: [
        {
          designation: "Product Manager",
        },
      ],
      user_profile: {
        instagram_url: "https://instagram.com/alicejohnson",
        linkedin_url: "https://linkedin.com/in/alicejohnson",
      },
    },
  },
];

export default function BookingsPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [editionsList, setEditionsList] = useState([]);

  const [filterData, setFilterData] = useState({
    status: null,
    edition: null,
    second_payment_status: null,
    visa_status: null,
    payment_status: null,
    flight_status: null,
    curation_status: null,
    destination: null,
    project: null,
    joining_date: null,
  });

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getEditions();
    getStatus();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, debouncedSearchTerm, filterData, id]);

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);

    const editionId = id == ":id" ? null : filterData?.edition || id;

    console.log(editionId, "editionId");

    fetchData(
      `api/booking/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&search=${encodeURIComponent(debouncedSearchTerm || "")}&status=${
        filterData?.status || ""
      }&edition=${editionId || ""}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div class="position-relative ">
                  <i class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>

                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search customer here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <div className="position-relative mr-4">
                  <div
                    className="btn btn-icon btn-primary btn-sm fw-bold me-2"
                    onClick={() => {
                      setOpenFilterDrawer(!openFilterDrawer);
                    }}
                  >
                    <i class="ki-outline ki-setting-4 fs-2x"></i>
                  </div>
                </div>

                {/* <div className="position-relative ">
                  <Select
                    value={filterData?.status}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setFilterDataValue("status", value)}
                    placeholder="Select Status"
                    style={{ width: "200px" }}
                    options={statusList.map((h) => ({
                      label: h.display_name,
                      value: h.value,
                    }))}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("status", null);
                    }}
                    size="large"
                  />
                </div>
                <div className="position-relative ">
                  <Select
                    value={filterData?.edition}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setFilterDataValue("edition", value)}
                    placeholder="Select Edition"
                    style={{ width: "200px" }}
                    options={editionsList}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("edition", null);
                    }}
                    size="large"
                  />
                </div> */}
              </div>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        {/*begin::Table*/}
                        <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-gray-900 bg-light-primary">
                              <th className="min-w-205px">Details</th>
                              <th className="min-w-125px">Edition</th>
                              <th className="min-w-150px">Status</th>
                              <th className="min-w-75px">Age</th>
                              <th className="min-w-125px">Payment Date</th>
                              <th className="min-w-150px ">Resource Manger</th>
                              <th className="min-w-125px">Explore more</th>
                              <th className="min-w-125px text-center">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold fs-7">
                            {data?.map((item) => {
                              const { user } = item;
                              const age =
                                new Date().getFullYear() -
                                new Date(user.date_of_birth).getFullYear();
                              return (
                                <tr key={item.id}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                        <img
                                          src={user.photo || "/media/blank.png"}
                                          alt="user"
                                        />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <Link
                                          to={`${routesList[
                                            "bookings_detail"
                                          ].path.replace(":id", item.id)}`}
                                          className="text-primary text-hover-primary mb-1 fw-bolder fs-5 min-w-200px"
                                        >
                                          {user.first_name || "-"}{" "}
                                          {user.last_name || "-"}
                                        </Link>
                                        <div className="text-gray-600">
                                          {user.user_work[0]?.designation ||
                                            "N/A"}
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td>{item.edition_name || "-"}</td>

                                  <td>
                                    <span
                                      className={`badge badge-light-${
                                        item.status == 1
                                          ? "warning"
                                          : item.status == 2
                                          ? "danger"
                                          : item?.status == 3
                                          ? "success"
                                          : "dark"
                                      }`}
                                    >
                                      {/* {item.status == 1
                                        ? "Pending"
                                        : item.status == 2
                                        ? "Abandon"
                                        : item?.status == 3
                                        ? "Approved"
                                        : "Attended"} */}
                                      {item.status_labels || "-"}
                                    </span>
                                  </td>
                                  <td>{age || "-"}</td>

                                  <td>{item.payment_date || "-"}</td>
                                  <td>{item.resource_manager || "-"}</td>

                                  <td>
                                    <div className="d-flex flex-shrink-0">
                                      <a
                                        href={user.user_profile.instagram_url}
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="lab la-instagram fs-2x text-primary" />
                                      </a>
                                      <a
                                        href={user.user_profile.linkedin_url}
                                        className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                                      >
                                        <i className="lab la-linkedin fs-2x text-primary" />
                                      </a>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="d-flex">
                                      {/* On Boarding Icon */}
                                      <Tooltip title="On Boarding">
                                        <a className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                          <i className="ki-outline ki-calendar-add fs-2x text-success"></i>{" "}
                                          {/* Changed to text-success */}
                                        </a>
                                      </Tooltip>

                                      {/* Flight Icon */}
                                      <Tooltip title="Flight">
                                        <a className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                          <i className="ki-outline ki-airplane fs-2x text-warning"></i>{" "}
                                          {/* Changed to text-warning */}
                                        </a>
                                      </Tooltip>

                                      {/* Subtitle Icon */}
                                      <Tooltip title="Visa">
                                        <a className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                          <i className="ki-outline ki-subtitle fs-2x text-info"></i>{" "}
                                          {/* Changed to text-info */}
                                        </a>
                                      </Tooltip>

                                      {/* Passport Check Icon */}
                                      <Tooltip title="Passport Check">
                                        <a className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                          <i className="ki-outline ki-password-check fs-2x text-danger"></i>{" "}
                                          {/* Changed to text-danger */}
                                        </a>
                                      </Tooltip>

                                      {/* Full Payment Done Icon */}
                                      <Tooltip title="Full Payment Done">
                                        <a className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                          <i className="ki-outline ki-check-circle fs-2x text-success"></i>{" "}
                                          {/* Changed to text-success */}
                                        </a>
                                      </Tooltip>

                                      {/* Notes Icon with Popover */}
                                      <Tooltip title="Notes">
                                        <Popover
                                          content={
                                            <div>
                                              <p>Here is some operation:</p>
                                              <div
                                                className="btn btn-primary btn-sm"
                                                onClick={() => {
                                                  console.log("clicked");
                                                }}
                                              >
                                                Perform Operation
                                              </div>
                                            </div>
                                          }
                                          title="Notes Action"
                                          trigger="click"
                                        >
                                          <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                            <i className="ki-outline ki-notepad fs-2x text-gray-500"></i>{" "}
                                            {/* Changed to text-secondary */}
                                          </div>
                                        </Popover>
                                      </Tooltip>

                                      {/* Room Icon */}
                                      <Tooltip title="Room">
                                        <a className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm">
                                          <i className="ki-outline ki-home fs-2x text-dark"></i>{" "}
                                          {/* Changed to text-dark */}
                                        </a>
                                      </Tooltip>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        {/*end::Table*/}
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      {openFilterDrawer && (
        <BookingsFilterDrawer
          show={openFilterDrawer}
          onHide={() => {
            // set_user_data({});
            setOpenFilterDrawer(false);
            // setLoading(true);
            // getData();
          }}
          // data={user_data}
          filterData={filterData}
          setFilterDataValue={setFilterDataValue}
          editionsList={editionsList}
          statusList={statusList}
          editionId={id}
        />
      )}
    </div>
  );
}
